import React, {useState, useEffect} from 'react'
import { HashLink } from 'react-router-hash-link';
//import MockRepository from '../MockRepository'

const ProjectItem = (item) => {

    return (
        <div key={"project_item_" + item.id} className='project-item'>
            
            <HashLink to={"/projects/" + item.slug} className="col-3 fadeIn project-item-link">
                <img src={item.imageURL()} alt={item.title}/>
                <div className='project-item-list-title'>{item.title}</div>
                <div className='project-item-list-preamble'>{item.preamble}</div>
            </HashLink>
            
        </div>
    )
}

const ProjectsList = (props) => {
    const [menuItems, setMenuItems] = useState([]);
    const {repository} = props;

    const fetchItems = async () => {
        const items = await repository.getItems();
        setMenuItems(items.map((item) => ProjectItem(item)));
    };
// <ProjectsList repository={repository}/>
    const handleRepositoryChange = () => {
        fetchItems();
    };

    useEffect(() => {

        repository.subscribe(handleRepositoryChange);
        fetchItems();
        return () => { repository.unsubscribe(handleRepositoryChange); }

    }, [repository]);

    return (
        <div className='row'>
             <div id="love">
                 <h2 className="section-title ">Explorations</h2>
                 <p className='section-subtitle'>
                 The passion for exploring technologies is a dynamic journey marked by insatiable curiosity and a relentless pursuit of innovation. Enthusiasts find joy in pushing boundaries, experimenting with novel solutions, and contributing to the ever-evolving technological landscape.
                 </p>
            </div>
             <aside id="gallery" className="scrollto clearfix">
             
              {menuItems}
          </aside>
        </div>
        
       
    )
}

export default ProjectsList