import React, {useState, useEffect} from 'react'
import { Outlet, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
//import Blockchain from '../Blockchain';
import {Button} from '@mui/material'
import $ from 'jquery';

const Header = (props) => {
    const {repository, blockchain} = props;
    const [isAuthorized, setIsAuthorized] = useState(blockchain.isAuthorized);
    const [menuExpanded, setMenuExpanded] = useState(false);
    
    const handleBlockchainChange = () => {
      setIsAuthorized(blockchain.isAuthorized);
    };
    
    const handleMenuClick = () => {
      if (menuExpanded) {
        setMenuExpanded(false);
        $("nav#nav-mobile ul").slideUp(250);
        $(this).removeClass("open");
      } else {
        setMenuExpanded(true);
        $("nav#nav-mobile ul").slideDown(250);
        $(this).addClass("open");
      }
    };

    useEffect(() => {

      if (!!$.prototype.scrollUp) {
        $.scrollUp();
      }
      $("#nav-mobile").html($("#nav-main").html());

      $("#nav-mobile").html($("#nav-main").html());
      $("#nav-mobile ul a").on("click",function() {
        if (menuExpanded) {
          setMenuExpanded(false);
          $("nav#nav-mobile ul").slideUp(250);
          $("#nav-trigger span").removeClass("open");
        }
      });
    
      /* Sticky Navigation */
      if (!!$.prototype.stickyNavbar) {
        $('#header').stickyNavbar();
      }

      blockchain.subscribe(handleBlockchainChange);
      return () => {  blockchain.unsubscribe(handleBlockchainChange); }
      //   if (blockchain.hasWalletApp()) {
        
      //     const connect = async () => { 
      //         blockchain.connect();
      //     };
      //     connect();
      // }
    }, [blockchain]);

    const connectWalletHandler = (event) => { 
      //event.preventDefault();
      const connect = async () => {
          await repository.connect();
      }
      connect();
  }
  return (
    <>
      <header id="banner" className="scrollto clearfix" data-enllax-ratio=".5">
        <div id="header" className="nav-collapse">
            <div className="row clearfix">
                <div className="col-1">
                    <div id="logo">
                        <img src="/logo.png" id="banner-logo" alt="Axel IT AB"/>
                    </div>

                    <aside>

                        <ul className="social-icons">
                            <li>
                                <a target="_blank" title="Facebook" href="https://www.facebook.com/username">
                                    <i className="fa fa-facebook fa-1x"></i><span>Facebook</span>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" title="Google+" href="http://google.com/+username">
                                    <i className="fa fa-google-plus fa-1x"></i><span>Google+</span>
                                </a>
                            </li>
                        </ul>

                    </aside>

                    <nav id="nav-main">
                        <ul>
                        <li>
                            <Link to="/">Home</Link>
                            </li>
                            <li>
                                <HashLink to="/#about">Faith</HashLink>
                            </li>
                            <li>
                                <HashLink to="/#love">Love</HashLink>
                            </li>
                            <li>
                                <HashLink to="/#services">Philosophy</HashLink>
                            </li>
                        </ul>
                    </nav>
                    <div id="nav-trigger"><span onClick={handleMenuClick}></span></div>
                    <nav id="nav-mobile"></nav>
                    <div style={{display: isAuthorized ? "inline" : "none"}}>
                      <HashLink to="/projects/new#project-editor">New projact</HashLink>
                    </div>
                    <div style={{display: isAuthorized ? "none" : "inline"}}>
                      <Button onClick={connectWalletHandler}>
                        {blockchain.hasWalletApp() ? "Hej hej" : ""}
                      </Button>
                    </div>
                </div>
            </div>
        </div>
        
      </header>

      <Outlet />
    </>
  )
};

export default Header;
