import assert from "assert";

class MockCache {

    constructor() { 
        this.items = []; 
        this.isValid = false;
    }

    async start() { this.started = true; }

    //TODO: Fix with timestamps
    setIsValid() { this.isValid = true; }

    getItems() {
        return this.items; 
    }

    async add(item) { 
        assert(this.started, "cache not started");
        this.items.push(item); 
    }

    async delete(item) {
        assert(this.started, "cache not started");

        var newShiet = [];
        for(var i = 0; i < this.items.length; i++) {
            if (this.items[i].id !== item.id) {
                newShiet.push(this.items[i]);
            }
        }
        this.items = newShiet;
    }

    async update(item) {
        assert(this.started, "cache not started");

        for(var i = 0; i < this.items.length; i++) {
            if (this.items[i].id == item.id) {
                this.items[i].title = item.title;
                this.items[i].body = item.body;
                this.items[i].slug = item.slug;
                this.items[i].preamble = item.preamble;
                this.items[i].cids = item.cids;
                return;
            }
        }

        throw Error("Item with id " + item.id + " not found.");
    }

    async getItemBy(slug) {
        assert(slug, "slug was nil");
        const item = this.items.find(item => {
            return item.slug == slug;
        });

        if (item == null) {
            //console.log("Available items in cache:", this.items);
            console.log(`Argh! item with slug not found: '${slug}'.`);
        }
        return item;
    }

}

export default MockCache;