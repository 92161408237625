import { Mutex, MutexInterface } from 'async-mutex';
import assert from "assert";

class DefaultRepository {

    constructor(blockchain, storage, cache) {
        this.blockchain = blockchain;
        this.storage = storage;
        this.cache = cache;
        this.isLoading = false;
        this.isLoadingItems = false;
        this.subscribers = [];
        this.lock = new Mutex();
    }

    async start() {
        await this.storage.start();
        await this.cache.start();
        this.started = true;
    }

    subscribe(callback) {
        this.subscribers.push(callback);
    }

    unsubscribe(callback) {
        this.subscribers = this.subscribers.filter(func => func != callback);
     }

    emitChange(tag) {
        //console.log("Repository update:", tag);
        this.subscribers.forEach(callback => callback());
    }

    async connect() {
        this.isLoading = true;
        await this.blockchain.connect();
        this.storage.configure({jwt: await this.blockchain.getApiKey() });
        this.emitChange("connect");
        this.isLoading = false;
    }

    async create(item) {
        assert(this.started, "Not started");
        await this.lock.acquire();
        this.isLoading = true;
        const cids = { body : "QmYkBJ4arTB2C1SQQtbwWeLuT9aK39mqDWPv5gvozysMSa", preamble: "QmcrpqD99E2zKPWD5puUdRLbTRwih67hSvNrEgHxiJr6Ev", image: "QmSRVf73BphzwdVX7SuAF56USFLG9aiDagGFc56muMfCe7"}
            //await this.storage.save(item);
        item.cids = cids;
        const newItem = await this.blockchain.create(item);
        this.cache.add(newItem);
        this.lock.release();
        this.emitChange("create");
        this.isLoading = false;
        return newItem;
    }

    async update(item) {
        assert(this.started, "Not started");
        assert(item.cids.body && item.cids.preamble && item.cids.image, "(some) item cids properties are missing.");
        assert(item.id != null, "Item id was null");
        await this.lock.acquire();
        this.isLoading = true;
        const newCids = await this.storage.save(item);
        const oldCids = item.cids;
        item.cids = newCids;
        await this.blockchain.update(item);
        if (newCids.body !== oldCids.body) { await this.storage.delete(oldCids.body); }
        if (newCids.preamble !== oldCids.preamble) { await this.storage.delete(oldCids.preamble); }
        this.cache.update(item);
        this.lock.release();
        this.emitChange("update");
        this.isLoading = false;
        return item;
    }

    async delete(item) {
        assert(this.started, "Not started");
        assert(item.cids.body && item.cids.preamble, "Item cids is missing");
        assert(item.id != null, "Item id was null");
        await this.lock.acquire();
        this.isLoading = true;
        await this.blockchain.delete(item);
        await this.storage.delete(item.cids.body);
        await this.storage.delete(item.cids.preamble);
        this.cache.delete(item);
        this.isLoading = false;
        this.lock.release();
        this.emitChange("delete");
    }

    async loadItems() {
       
        if (this.isLoadingItems == false) {
            this.isLoadingItems = true;
            var items = await this.blockchain.getItems();
            for(var i = 0; i < items.length; i++) {
                items[i].body = await this.storage.load(items[i].cids.body);
                items[i].preamble = await this.storage.load(items[i].cids.preamble);
                this.cache.add(items[i]);
            }
            console.log("############## Parsed items:", items)
            
            this.emitChange("loadItems");

            this.isLoadingItems = false;
        }
    }

    async getItems() {
        assert(this.started, "Cant't retrieve items: Repository not started.");
        await this.lock.acquire();
        if (!this.cache.isValid) {
            this.cache.setIsValid(); // TODE: fix timestamps
            await this.loadItems();
        }
        this.lock.release();
        return this.cache.getItems()
        .sort(function(first, second)
        {
            //console.log("MOROS:", first.sortOrder, second.sortOrder);
            
            return ((first.sortOrder < second.sortOrder) ? -1 : ((first.sortOrder > second.sortOrder) ? 1 : 0));
        });
    }

    async getItemBy(slug) {
        await this.getItems();
        return await this.cache.getItemBy(slug);
    }

}

export default DefaultRepository;