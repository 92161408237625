
class Configuration {

    static instance() {
        return configuration;        
    }

    constructor () {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log("Using debug configuration");
            const config = require('../develop.config.json');
            this.contractAddress = config.contractAddress;
            this.rpcURL = config.rpcURL;
            this.cvURL = config.cvURL;
        } else {
            console.log("Using release configuration");
            const config = require('../release.config.json');
            this.contractAddress = config.contractAddress;
            this.rpcURL = config.rpcURL;
            this.cvURL = config.cvURL;
        }
    }
}

var configuration = new Configuration();

export default Configuration;