
//const ethers = require("ethers")

import { assert, ethers } from 'ethers'; // , Signer, signer
import Item from './shared/Item';
import Configuration from './shared/Configuration';

const abi = require('./AxelitAuthABI.json');


class Blockchain {

    constructor() {
        
        //console.log("SUMO", CONTRACT_ADDRESS_DEBUG);
        this.provider = new ethers.JsonRpcProvider(Configuration.instance().rpcURL);
        this.contract = new ethers.Contract(Configuration.instance().contractAddress, abi, this.provider);
        this.tmpContract = new ethers.Contract(Configuration.instance().contractAddress, abi, this.provider);
        this.isAuthorized = false;
        this.address = null;
        this.signer = null;
        this.subscribers = [];
        this.jwt = null;
    }

    subscribe(callback) {
        this.subscribers.push(callback);
    }
    unsubscribe(callback) {
        this.subscribers = this.subscribers.filter(func => func != callback);
     }

    async create(item) {
        console.log("Detta fick jag: ", item);
        assert(this.isAuthorized, "Not authenticated");
        assert(item.id == null, "item.id was not null (" + item.id + ")");
        assert(item.cids.body && item.cids.preamble && item.cids.image, "cids not provided: " + item.cids);
        item.id = 42; // Id needs to be set

        console.log("Creating item: ", item.asJSON());

        const id = await this.contract.createItem(item.asJSON());
        item.id = id;
        console.log("Created item with id:", id);
        return item;
    }

    async delete(item) {
        assert(this.isAuthorized, "Not authenticated");
        assert(item.id != null, "Item id was null");
        console.log("Deleting item with id: ", item.id, " title: ", item.title);
        await this.contract.deleteItem(item.asJSON());
    }

    async update(item) {
        assert(this.isAuthorized, "Not authorized");
        assert(item.id != null, "Item id was null");
        assert(item.cids.body && item.cids.preamble && item.cids.image, "cids not provided: " + item.cids);
        console.log("Updating item with id: ", item.id, " title: ", item.title);
        await this.contract.updateItem(item.asJSON());
    }

    async getApiKey() {
        assert(this.isAuthorized, "Not authorized");
        if (this.jwt == null) { 
            console.log("Fetching JWT")
            this.jwt = await this.contract.getJWT();
        }

        return this.jwt;
   }

    async getItems() {
        var items = [];
        const itemsMetadata = await this.contract.getItems();
        for (var i = 0; i < itemsMetadata.length; i++) {
            const itemMeta = itemsMetadata[i];
            if (itemMeta["id"] != undefined && itemMeta["id"] != null && itemMeta["bodyCid"]) {
                console.log("Got item: ", itemMeta);
                items.push(new Item(
                    itemMeta["id"], 
                    itemMeta["sortOrder"],
                    itemMeta["title"], 
                    itemMeta["slug"],
                    itemMeta["extraText"],
                    itemMeta["extraNumber"], 
                    "", 
                    "", 
                    { body: itemMeta["bodyCid"], preamble: itemMeta["preambleCid"], image: itemMeta["imageCid"] }
                ));
            } else { console.log("Got empty item from contract.", itemMeta, itemMeta["bodyCid"], itemMeta["id"]); }
        }
        return items;
    }

    hasWalletApp() {
        return window.ethereum;
    }

    async connect() {
        console.log("Connecting to wallet");
        if (this.isAuthorized) { return; }
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.provider = new ethers.BrowserProvider(window.ethereum);
        this.signer = await this.provider.getSigner();
        this.address = this.signer.address
        this.contract = new ethers.Contract(Configuration.instance().contractAddress, abi, this.signer);
        this.isAuthorized = await this.contract.isAuthenticated();
        this.emitChange();
    }

    emitChange() {
        this.subscribers.forEach(callback => callback());
    }

}

export default Blockchain;