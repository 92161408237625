//import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
//import MenuHeader from './pages/ProjectsList';
//import MockRepository from './MockRepository';
//import ErrorPage from './ErrorPage';
import Header from "./pages/Header";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import ProjectPage from './pages/ProjectPage';
import EditProjectItem from './pages/EditEditProjectItem';
import Blockchain from './Blockchain';
//import DefaultIPFSStorage from './DefaultIPFSStorage';
import DefaultRepository from './DefaultRepository';
import MockCache from './MockCache';
import PinataIPFS from './PinataIPFS';

const blockchain = new Blockchain();
//const repository = new MockRepository(blockchain);
const ipfs = new PinataIPFS();

const repository = new DefaultRepository(blockchain, ipfs, new MockCache());
await repository.start();

function App() {

  return (
    <div id="wrapper">
    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header repository={repository} blockchain={blockchain} />}>
          <Route index element={<Home repository={repository}/>} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="/projects/:slug"
            element={<ProjectPage repository={repository} blockchain={blockchain}/>}
          />
          <Route
            path="/projects/:slug/edit"
            element={<EditProjectItem repository={repository} blockchain={blockchain} />}
          />
          <Route
            path="/projects/new"
            element={<EditProjectItem repository={repository} blockchain={blockchain}/>}
          />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>

  );
}

export default App;
