import axios from 'axios';
import assert from "assert";
const FormData = require('form-data');

class PinataIPFS {

    async start() { 
    }

    async createFile(id, text) {
      assert(this.jwt, "jwt not defined");

      const blob = new Blob([text], { type: 'text/plain' }); // Default to text/plain for now
      const form = new FormData();

      form.append('file', blob, id);
        const response = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", form, {
          headers: {
            'Authorization': `Bearer ${this.jwt}`
          }
        });
       console.log("IPFS pinning successful");

       return response.data.IpfsHash;
    }

    async configure(options) {
        assert(options.jwt, "´jwt´ is missing from options");
        
        this.jwt = options.jwt;

        console.log("JWT set: ", options.jwt);
    }

    async save(item) {
      
      const id = item.id ?? "new";

      const bodyCid = await this.createFile(`blog_id_${id}.md`, item.body);
      const preambleCid = await this.createFile(`preamble_id_${id}.md`, item.preamble);

      return { body: bodyCid, preamble: preambleCid, image: item.cids.image } // just copy image cid for now
    }

    async delete(cid) {
        assert(this.jwt, "jwt not defined");
        console.log("Deleting file: ", cid);
        const response = await axios.delete(`https://api.pinata.cloud/pinning/unpin/${cid}`, {
            headers: {
              'Authorization': `Bearer ${this.jwt}`
            }
          });
         console.log("IPFS deletion successful", response);
    }

    async load(cid) {
        assert(cid, "cid was null!");
        const response = await axios.get("https://gateway.pinata.cloud/ipfs/" + cid);
        return response.data;
    }
}

export default PinataIPFS;