import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import '../MockRepository';
import Item from '../shared/Item';

//import Blockchain from '../Blockchain';

const PROJECTS_PATH = "/projects/";

const EditProjectItem = (props) => {

    const { slug } = useParams();
    let navigate  = useNavigate();
    const { repository, blockchain } = props;
    
    const [itemProperties, setItemProperties] = useState(Item.empty());
    const [sortOrder, setSortOrder] = useState("0");
    const [body, setBody] = useState("");
    const [title, setTitle] = useState("");
    const [preamble, setPreamble] = useState("");
    const [imageCid, setImageCid] = useState("QmSRVf73BphzwdVX7SuAF56USFLG9aiDagGFc56muMfCe7");
    const [slugName, setSlugName] = useState("");
    const [isAuthorized, setIsAuthorized] = useState(blockchain.isAuthorized);
    const [showSpinner, setShowSpinner] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        const updateItem = async () => {
            
            setShowSpinner(true);
            if (slugName == null || slugName.match(/[ ]+/) !== null) {
                setShowSpinner(false);
                return alert("Invalid slug");
            }
            if (title === null || title.trim().length === 0) {
                setShowSpinner(false);
                return alert("Invalid title");
            }

            const item = new Item(
                itemProperties.id,
                Number(sortOrder),
                title, 
                slugName,
                "", // extraText (not used)
                0, // extraNumber (not used)
                body, 
                preamble, 
                { body: itemProperties.cids.body, preamble: itemProperties.cids.preamble, image: imageCid }
            );

            if (itemProperties.id != null) {
                console.log("SENLE:", item);
                await repository.update(item);
            } else {
                const newItem = await repository.create(item);
                setItemProperties(newItem);
                setItemValues(newItem);
            }
            setShowSpinner(false);
            navigate(PROJECTS_PATH + slugName);
        }
        updateItem();
    }
    
    const handleTitleChange = (event) => { setTitle(event.target.value); }
    const handleBodyChange = (event) => { setBody(event.target.value); }
    const handleSlugChange = (event) => { setSlugName(event.target.value); }
    const handlePreambleChange = (event) => { setPreamble(event.target.value); }
    const handleImageCidChange = (event) => { setImageCid(event.target.value); }
    const handleSortOrderChange = (event) => { setSortOrder(event.target.value); }

    const fetchItem = async () => {
        if (slug) {
            setShowSpinner(true);

            const item = await repository.getItemBy(slug);
            if (item) {
                setItemProperties(item);
                setSortOrder(item.sortOrder);
                setTitle(item.title);
                setBody(item.body);
                setSlugName(item.slug);
                setPreamble(item.preamble);
                setImageCid(item.cids.image);
            }
        }
        setShowSpinner(false);
    };

    const handleRepositoryChange = () => { 
        fetchItem();
    }

    const handleBlockchainChange = () => {
        setIsAuthorized(blockchain.isAuthorized);
    };

    useEffect(() => {
        fetchItem();
        repository.subscribe(handleRepositoryChange);
        blockchain.subscribe(handleBlockchainChange);
        return () => { 
            repository.unsubscribe(handleRepositoryChange); 
            blockchain.unsubscribe(handleBlockchainChange);
        }
    }, [repository, blockchain]);

    const handleDelete = (event) => {
        
        event.preventDefault();
        const deleteItem = async () => {
            setShowSpinner(true);
            await repository.delete(itemProperties);
            navigate("/")
            setShowSpinner(false);
        }
        deleteItem();
    }

    const setItemValues = (item) => {
        setTitle(item.title);
        setBody(item.body);
        setSlugName(item.slug);
        setSortOrder(`${item.sortOrder}`);
    };

    useEffect(() => {
        return () => { 
            repository.unsubscribe(handleRepositoryChange); 
            blockchain.unsubscribe(handleBlockchainChange);
        }
    }, [slug, blockchain, repository]);

    if (itemProperties != null) {
        return (
            <div className='project-editor' id="project-editor">
                <div style={{display: showSpinner ? "none" : "inline"}}>
                    <div style={{display: isAuthorized ? "inline" : "none"}}>
                        <form onSubmit={handleSubmit}>
                            <p><input value={title} onChange={handleTitleChange} placeholder="(title)"/></p>
                            
                            <p><input value={imageCid} onChange={handleImageCidChange} placeholder="(image CID)"/></p>
                            <p><input value={sortOrder} onChange={handleSortOrderChange} placeholder="(sortOrder)"/></p>
                            <textarea type="text" value={preamble} rows="5" cols="100" onChange={handlePreambleChange}/>
                            <textarea type="text" value={body} rows="30" cols="100" onChange={handleBodyChange}/>
                            <p><input value={slugName} onChange={handleSlugChange} placeholder="(slug)"/></p>
                            <p><input type="submit" /></p>
                            <p><input type="submit" value="Delete" onClick={handleDelete}/></p>
                        </form>
                    </div>
                    <div className="project-body"><Markdown remarkPlugins={[remarkGfm]}>{body}</Markdown></div>
                    <div style={{display: isAuthorized ? "none" : "inline"}}>
                        401!
                    </div>
                </div>
                <div style={{display: showSpinner ? "inline" : "none"}}>
                    <h2>Loading ... Loading ...</h2>
                </div>
               
            </div>
        );

    } else {
        return (<p>... loading ...</p>)
    }
  };

  export default EditProjectItem;