import React, {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const ProjectPage = (props) => {

    const { slug } = useParams();
    const { repository, blockchain } = props;
    const [itemProperties, setItemProperties] = useState(null);
    const [isAuthorized, setIsAuthorized] = useState(blockchain.isAuthorized);

    const fetchItem = async () => {
        setItemProperties(await repository.getItemBy(slug));
    };

    const handleRepositoryChange = () => { 
        fetchItem();
    }

    const handleBlockchainChange = () => {
        setIsAuthorized(blockchain.isAuthorized);
    };

    useEffect(() => {
        repository.subscribe(handleRepositoryChange);
        blockchain.subscribe(handleBlockchainChange);
        fetchItem();
        return () => { 
            repository.unsubscribe(handleRepositoryChange); 
            blockchain.unsubscribe(handleBlockchainChange);
        }
    }, [repository]);

    if (itemProperties != null) {
        return (
            <main id="content">
            <div id="project-page-container" className='row clearfix'>
                <h1>{itemProperties.title}</h1>
                <div className="project-body">
                    <Markdown remarkPlugins={[remarkGfm]}>{itemProperties.body}</Markdown>
                </div>
                <Link to={"/projects/" + itemProperties.slug + "/edit"} style={ {display: isAuthorized ? "inline" : "none"}}> EDIT </Link>
            </div>
            </main>
            
            );
    } else {
        return (<p>... loading ...</p>)
    }
  };
  
export default ProjectPage;
  