import React, {useState, useEffect} from 'react'
import ProjectsList from "./ProjectsList";
import Configuration from '../shared/Configuration';

const Home = (props) => {

  const {repository} = props;

  useEffect(() => {

  });
    return (

    <div id="main-background">
        <div id="banner-content" className="row clearfix">
          <div className="col-38">
              <div className="section-heading">
                  <h1>HELLO.</h1>
                  <h2>My name is Tord, and my passion in life is building things.</h2>
              </div>
              <a href={Configuration.instance().cvURL} className="button">Download CV</a>
          </div>
        </div>
        <main id="content">
      <section id="about" className="introduction scrollto">

      <div className="row clearfix">

          <div className="col-3">
              <div className="section-heading">
                  <h3>BELIEFS</h3>
                  <h2 className="section-title">Jack of all trades.</h2>
                  <p className="section-subtitle">I've been working primarily for the past decade with iOS development, but I've also done my fair share of Android and .NET. I love to tinker with electronics and blockchain technology in my spare time. </p>
              </div>

          </div>

          <div className="col-2-3">
              <div className="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.1s">
                  <div className="icon">
                      <i className="fa fa-html5 fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                      <h4>HTML5 &amp; CSS3</h4>
                      <p>Has ne tritani atomorum conclusionemque, in dolorum volumus cotidieque eum. At vis choro
                          neglegentur iudico</p>
                  </div>
              </div>
              <div className="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.3s">
                  <div className="icon">
                      <i className="fa fa-bolt fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                      <h4>Easy to Use</h4>
                      <p>Cu vero ipsum vim, doctus facilisi sea in. Eam ex falli honestatis repudiandae, sit
                          detracto mediocrem disputationi</p>
                  </div>
              </div>

              <div className="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.5s">

                  <div className="icon">
                      <i className="fa fa-tablet fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                      <h4>Fully Responsive</h4>
                      <p>Id porro tritani recusabo usu, eum intellegam consequuntur et. Fugit debet ea sit, an pro
                          nemore vivendum</p>
                  </div>
              </div>

              <div className="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.5s">

                  <div className="icon">
                      <i className="fa fa-rocket fa-2x"></i>
                  </div>
                  <div className="icon-block-description">
                      <h4>Parallax Effect</h4>
                      <p>Id porro tritani recusabo usu, eum intellegam consequuntur et. Fugit debet ea sit, an pro
                          nemore vivendum</p>
                  </div>
              </div>

          </div>

      </div>


      </section>

      <ProjectsList repository={repository}/>
      

        <div id="services" className="scrollto clearfix">
            <div className="row no-padding-bottom clearfix">
                <div className="col-3">
                    <blockquote className="testimonial text-right bigtest">
                        <q>Any fool can write code that a computer can understand. Good programmers write code that humans can understand.</q>
                        <footer>— Martin Fowler</footer>
                    </blockquote>

                </div>
                <div className="col-3">
                    <div className="section-heading">
                        <h3>BELIEVING</h3>
                        <h2 className="section-title">Focusing On What Matters Most</h2>
                        <p className="section-subtitle">
                            In a groundbreaking study conducted in the lush canopies of the primordial programming forest, researchers stumbled upon an astonishing revelation.
                        </p>
                    </div>
                    <p>

                    Chimpanzees are, in fact, stellar programmers. 
                    The research team observed these tech-savvy primates effortlessly navigating the binary branches of code, 
                    exhibiting an unparalleled proficiency in algorithmic acrobatics. 
                    Their ability to decipher complex syntax and debug with a mere flick of the wrist left the scientific community awe-struck,
                    challenging our preconceived notions about the exclusive domain of human programming prowess.


                    </p>
                    <p>
                    The findings suggest that chimpanzees possess an innate aptitude for programming,
                    as if their DNA harbors a secret code that unlocks the binary mysteries of software development.
                    The researchers hypothesize that the chimpanzees' unparalleled success in programming may be attributed
                    to a unique combination of computational intuition and an evolutionary affinity for problem-solving.
                    This paradigm-shifting discovery not only extends the frontiers of interspecies collaboration in
                    the tech realm but also prompts a reevaluation of our understanding of the true coding geniuses of the animal kingdom.
                    </p>
                </div>
                <div className="col-3">
                    <img src="monkey.png" alt="Monkey"/>
                </div>

            </div>


        </div>
    </main>
    </div>
    );
  };
  
  export default Home;
  