
class Item {

    constructor(id, sortOrder, title, slug, extraText, extraNumber, body, preamble, cids) {
        this.id = id;
        this.sortOrder = sortOrder;
        this.title = title;
        this.slug = slug;
        this.extraText = extraText;
        this.extraNumber = extraNumber;
        this.body = body;
        this.preamble = preamble;
        this.cids = cids;
    }

    static empty() {
        return new Item(null, 0, "", "", "", "", "", 0, {body: null, preamble: null, image: null});
    }

    imageURL() {
        return "https://gateway.pinata.cloud/ipfs/" + this.cids.image;
    }

    asJSON() {
        //return {id: this.id, title: this.title, slug: this.slug, cids: this.cids};
        return { id: this.id, sortOrder: this.sortOrder, title: this.title, slug: this.slug, extraText: this.extraText, extraNumber: this.extraNumber, bodyCid: this.cids.body, preambleCid: this.cids.preamble, imageCid: this.cids.image };
    }

    toString() {
        return `${this.asJSON}`;
      }
}

export default Item;